exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-post-jsx-content-file-path-home-runner-work-kntd-site-kntd-site-blog-essential-knitting-and-crochet-tools-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/home/runner/work/kntd-site/kntd-site/blog/essential-knitting-and-crochet-tools.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-home-runner-work-kntd-site-kntd-site-blog-essential-knitting-and-crochet-tools-mdx" */),
  "component---src-templates-post-jsx-content-file-path-home-runner-work-kntd-site-kntd-site-blog-history-of-knitting-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/home/runner/work/kntd-site/kntd-site/blog/history-of-knitting.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-home-runner-work-kntd-site-kntd-site-blog-history-of-knitting-mdx" */),
  "component---src-templates-post-jsx-content-file-path-home-runner-work-kntd-site-kntd-site-blog-knitting-gift-ideas-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/home/runner/work/kntd-site/kntd-site/blog/knitting-gift-ideas.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-home-runner-work-kntd-site-kntd-site-blog-knitting-gift-ideas-mdx" */),
  "component---src-templates-post-jsx-content-file-path-home-runner-work-kntd-site-kntd-site-blog-recent-trends-in-crochet-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/home/runner/work/kntd-site/kntd-site/blog/recent-trends-in-crochet.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-home-runner-work-kntd-site-kntd-site-blog-recent-trends-in-crochet-mdx" */),
  "component---src-templates-post-jsx-content-file-path-home-runner-work-kntd-site-kntd-site-blog-recent-trends-in-knitting-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/home/runner/work/kntd-site/kntd-site/blog/recent-trends-in-knitting.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-home-runner-work-kntd-site-kntd-site-blog-recent-trends-in-knitting-mdx" */),
  "component---src-templates-post-jsx-content-file-path-home-runner-work-kntd-site-kntd-site-blog-types-of-knitting-needle-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/home/runner/work/kntd-site/kntd-site/blog/types-of-knitting-needle.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-home-runner-work-kntd-site-kntd-site-blog-types-of-knitting-needle-mdx" */),
  "component---src-templates-post-jsx-content-file-path-home-runner-work-kntd-site-kntd-site-blog-what-is-ravelry-mdx": () => import("./../../../src/templates/post.jsx?__contentFilePath=/home/runner/work/kntd-site/kntd-site/blog/what-is-ravelry.mdx" /* webpackChunkName: "component---src-templates-post-jsx-content-file-path-home-runner-work-kntd-site-kntd-site-blog-what-is-ravelry-mdx" */)
}

